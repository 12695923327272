<template>
  <footer class="footer">
    <p class="copyright">© 2020 Orderly, LLC. All rights reserved.</p>
    <div class="icon-container">
      <!-- <MailSvg/>
      <TwitterSvg/>
      <YoutubeSvg/> -->
    </div>
  </footer>
</template>

<script>

// import MailSvg from "@/components/icons/MailButton";
// import TwitterSvg from "@/components/icons/TwitterButton";
// import YoutubeSvg from "@/components/icons/YoutubeButton";

export default {
  name: "Footer",
  components: {
    // MailSvg,
    // TwitterSvg,
    // YoutubeSvg
  }
}
</script>

<style scoped>

.footer {
  margin-top: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.copyright {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #646464;
}

.icon-container {
  margin-top: 20px;
  width: 192px;
  display: flex;
  justify-content: space-between;
}

</style>

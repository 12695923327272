<template>
  <div class="w-full flex justify-center" style="min-height: 100vh;">
    <div class="submission-container flex flex-col items-start flex-grow">
      <header class="ml-4 lg:ml-0">
        <button @click="() => $router.push('/')" class="flex items-center">
          <img aria-label="An arrow pointing to the left" aria-hidden="true" class="mr-2" src="@/assets/back-arrow.svg">
          <span class="back-text">Back to home</span>
        </button>
      </header>
      <main class="w-full">
        <h1 class="mt-0 lg:mt-8 info-header ml-6">
          Oops
        </h1>
        <h2 class="mt-0 lg:mt-2 ml-6">Something went wrong...</h2>
        <div class="error-monkey-container w-full flex flex-col items-center mt-8 sm:mt-24">
          <ErrorMonkey />

        </div>
        <div class="flex flex-row w-full justify-center mt-4 sm:mt-8">
          <button @click="$router.push('/signup')" class="btn-outlined mx-2">Try again</button>
          <button @click="$router.push('/')" class="btn-outlined">Back to home</button>
        </div>
      </main>
      <div class="flex-grow"/>
      <Footer class="mt-4 lg-mt-24" />
    </div>
  </div>
</template>

<script>
import ErrorMonkey from "@/components/icons/ErrorMonkey";
import Footer from "@/components/Footer";
export default {
  name: "Error",
  components: {Footer, ErrorMonkey}
}
</script>

<style scoped>

.submission-container {
  max-width: 840px;
  margin-top: 40px;
}

.info-header {
  font-size: 48px;
}

.error-monkey-container {
  max-width: calc(100% - 124px);
  margin-left: 62px;
  margin-right: 62px;
}

</style>
